import ff_id from '../assets/fishingForecast/ff_id.png'

import ff_en_1 from '../assets/fishingForecast/ff_en_1.png'
import ff_en_2 from '../assets/fishingForecast/ff_en_2.png'
import ff_en_3 from '../assets/fishingForecast/ff_en_3.png'

export default {
  fishingForecast: {
    title: 'Prakiraan Memancing',
    text: 'Cari tahu kapan ikan paling aktif di siang hari dan dapatkan pemberitahuan aktivitas ikan selanjutnya',
    imgUrl: ff_id,
    imgUrl1: ff_en_1,
    imgUrl2: ff_en_2,
    imgUrl3: ff_en_3,
  },
  continue: 'Lanjutkan'
}
