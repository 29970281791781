import slide_1 from './../assets/slider/slider_1.png'
import slide_2 from './../assets/slider/slider_2.png'
import slide_3 from './../assets/slider/slider_3.png'
import slide_4 from './../assets/slider/slider_4.png'

export const slides = [
  {
    id: 1,
    imageUrl: slide_1,
    title: 'Fishing Forecast',
    text: 'Check when fish the most active during the day and get notified before next high fish activity'
  },
  {
    id: 2,
    imageUrl: slide_2,
    title: 'Local Fishing Spots',
    text: 'Discover new local fishing spots and where fish are being caught'
  },
  {
    id: 3,
    imageUrl: slide_3,
    title: 'Tide & Weather Forecast',
    text: 'Plan your fishing trips using tide prediction, marine, weather forecast and solunar data'
  },
  {
    id: 4,
    imageUrl: slide_4,
    title: 'Fishing Knots',
    text: 'Use our guide to find the best knot for any situation. Our guide has more than 60 knots.'
  },
]

export const slideForecast = [
  {
    id: 1,
    imageUrl: slide_1,
    title: 'Fishing Forecast',
    text: 'Check when fish the most active during the day and get notified before next high fish activity'
  }
]

export const slideKnots = [
  {
    id: 4,
    imageUrl: slide_4,
    title: 'Fishing Knots',
    text: 'Use our guide to find the best knot for any situation. Our guide has more than 60 knots.'
  }
]

export const slidesB = [
  {
    id: 1,
    imageUrl: slide_1,
    title: 'Fishing Forecast',
    text: 'Check when fish the most active during the day and get notified before next high fish activity',
    isVariant: true
  },
  {
    id: 2,
    variant: 'b',
    title: 'Let’s get started!',
    text: 'Please enter your email to create a profile.',
    placeholder: 'Email',
  },
]

export const slidesC = [
  {
    id: 1,
    imageUrl: slide_1,
    title: 'Fishing Forecast',
    text: 'Check when fish the most active during the day and get notified before next high fish activity',
    isVariant: true
  },
  {
    id: 2,
    variant: 'c',
    title: 'Personalize your experience',
    text: 'Enter your email to continue',
    placeholder: 'Enter your email',
    bottomText: 'Your privacy is our value. Fishbox doesn\'t share your personal information with third parties.'
  },
]

export const gradient = 'linear-gradient(48.95deg, #079FC8 4.48%, #0AA6C9 41.01%, #50CBDB 84.95%, #69D8E2 100.82%, #71DCE4 111.95%, #76DFE5 117.66%, #76DFE5 117.66%, #77E0E5 118.3%, #77E0E5 118.3%, #ABFFEE 171.23%)'
export const questionsGadient = 'linear-gradient(44.65deg, #F3FAFC 0%, #F7FDFD 100%)'