import styled from 'styled-components'

const Slide = styled.div`
  display: flex;
  flex-direction: column;
`

Slide.Image = styled.div`
  height: 480px;
  display: flex;
  justify-content: center;
  padding: ${props => props.isVariant ? '23px 1.8rem 0 1.8rem': '0'};
  align-items: ${props => {
    if(props.slide === 4) {
      return 'flex-end'
    } else {
      return 'center'
    }
  }};
  overflow: hidden;
  margin-bottom: 1rem;
  img {
    //min-width: 100%;
    object-fit: cover;
    /* max-width: 22.4375rem; */
    /* max-height: 100%; */
    max-width: 100%;
  }
  
  // iphone 12 pro
  @media only screen 
    and (max-device-height : 896px) 
    and (orientation : portrait) { 
      height: 350px;
      margin-bottom: 1.5rem;
  }
  
  @media only screen 
    and (max-device-height : 844px) 
    and (orientation : portrait) { 
      height: 330px;
  }
  
  
  
  @media only screen 
    and (max-device-height : 818px) 
    and (orientation : portrait) { 
      height: 330px;
      margin-bottom: 1.5rem;
  }
  
  @media only screen 
    and (max-device-height : 669px) 
    and (orientation : portrait) { 
      height: 285px;
      max-width: ${props => {
        let maxWidth
        if(props.slide === 1 || props.slide === 3) {
          maxWidth = '90%'
        } else {
          maxWidth = '100%'
        }
          return maxWidth
        }}
    };
    margin: 0 auto;
    margin-bottom: 1rem;
`

Slide.Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  height: ${props => props.isVariant ? '7.5rem': '9.6875rem'};
  overflow: hidden;
  margin-bottom: 0.75rem;
  @media only screen 
    and (min-device-width : 375px) 
    and (max-device-height : 669px) 
    and (orientation : portrait) { 
      height: 8.125rem;
      margin-bottom: 0;
  }
`

Slide.Title = styled.div`
  font-weight: 800;
  color: #000000;
  font-size: 2rem;
  line-height: 2rem;
  text-align: center;
  margin-bottom: 1rem;
  height: ${props => props.isVariant ? '3rem': '4.0625rem'};
  
  @media(max-height: 669px) {
    height: ${props => props.isVariant ? '3rem': '4.0625rem'};
    margin-bottom: 0;
  }
`

Slide.Text = styled.div`
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.5rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  max-width: ${props => props.isVariant ? '310px': 'auto'};
  @media(max-height: 669px) {
    margin-bottom: 0.1rem;
  }
`

export {
  Slide
}