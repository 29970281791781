import ff_fr from '../assets/fishingForecast/ff_fr.png'
import ff_en_1 from '../assets/fishingForecast/ff_en_1.png'
import ff_en_2 from '../assets/fishingForecast/ff_en_2.png'
import ff_en_3 from '../assets/fishingForecast/ff_en_3.png'

export default {
  fishingForecast: {
    title: 'Prévisions de Pêche',
    text: 'Vérifiez quand les poissons sont les plus actifs de la journée et soyez averti avant la prochaine activité élevée',
    imgUrl: ff_fr,
    imgUrl1: ff_en_1,
    imgUrl2: ff_en_2,
    imgUrl3: ff_en_3,
  },
  continue: 'Continuer'
}
