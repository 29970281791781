import styled from 'styled-components'

const Intro = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 30vh;
  flex-grow: 1;
  padding-bottom: 5rem;
  @media(max-height: 669px) {
    padding-top: 15vh;
    /* justify-content: flex-start; */
  }
`


Intro.Top = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

Intro.Icon = styled.div`
  margin-bottom: 4.75rem;
  @media(max-height: 669px) {
    margin-bottom: 3rem;
  }
`

Intro.Title = styled.div`
  font-size: 2rem;
  line-height: 2.5rem;
  color: #ffffff;
  text-align: center;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  margin-bottom: 1.125rem;
  font-weight: 800;
`

Intro.Text = styled.div`
  font-size: 1.125rem;
  line-height: 1.5625rem;
  color: #ffffff;
  text-align: center;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  margin-bottom: 2rem;
  font-weight: 400;
`

Intro.Bottom = styled.div`
  padding-right: 1.875rem;
  padding-left: 1.875rem;
`

Intro.Button = styled.div`
  height: 4rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  line-height: 2rem;
  color: #00A1C6;
  background: #ffffff;
  font-weight: 900;
  cursor: pointer;
  -moz-user-select: none; /* Mozilla Firefox */
  -ms-user-select: none; /* Internet Explorer (не поддерживается) */
  -o-user-select: none; /* Opera Presto (не поддерживается) */
  -webkit-user-select: none;
`

export { Intro }