import React from 'react'
import { useHistory } from 'react-router-dom'
import { PrivacyBlock } from './styled'
import { privacyContent } from '../../constants/privacy'
import arrowIcon from './../../assets/other/arrow.svg'

const Privacy = () => {
  const history = useHistory()

  const handleGoBack = () => {
    history.goBack()
  }

  return (
    <PrivacyBlock>
      <PrivacyBlock.Header>
        Privacy Policy
        <PrivacyBlock.Header.Back onClick={handleGoBack}>
          <img src={arrowIcon} alt="Back"/>
        </PrivacyBlock.Header.Back>
      </PrivacyBlock.Header>
      <PrivacyBlock.Content>
        {
          privacyContent.map((item, index) => {
            return (
              <PrivacyBlock.Content.Block key={index}>
                <PrivacyBlock.Title>
                  {item.title}
                </PrivacyBlock.Title>
                {
                  item.texts.map((text, index) => {
                    return (
                      <PrivacyBlock.Text key={index}>
                        {text}
                      </PrivacyBlock.Text>
                    )
                  })
                }
              </PrivacyBlock.Content.Block>
            )
          })
        }
      </PrivacyBlock.Content>
    </PrivacyBlock>
  )
}

export default Privacy