import Email from './Email.jsx'
import { connect } from 'react-redux'
import { setEmailAction } from '../../../store/fish/actions'

const mapStateToProps = (state) => {
  return {
    email: state.fish.email
  }
}

const mapDispatchToProps = {
  setEmailAction
}

export default connect(mapStateToProps, mapDispatchToProps)(Email)
