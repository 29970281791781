import styled from 'styled-components'

const EmailBlock = styled.div`
  padding: 50px 25px 0 25px;
`

EmailBlock.Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${props => props.variant === 'c' ? '100px' : '35px'}  ;
 
`
EmailBlock.Top.Skip = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #B9B9B9;
`
EmailBlock.Center = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 58px;
`
EmailBlock.Title = styled.div`
  font-weight: 900;
  font-size: 28px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 8px;
  color: #000000;
`
EmailBlock.Text = styled.div`
  font-size: 17px;
  line-height: 22px;
  color: #515151;
  text-align: center;
  margin-bottom: 24px;
`
EmailBlock.Input = styled.div`
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 24px;
  position: relative;
  input {
    background: #FFFFFF;
    -webkit-appearance: none;
    -webkit-box-shadow: 0px 2px 10px rgba(130, 130, 130, 0.2);
    box-shadow: 0px 2px 10px rgba(130, 130, 130, 0.2);
    border-radius: 3px;
    width: 100%;
    max-width: 400px;
    height: 50px;
    border: none;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    padding-left: 12px;
    padding-right: 30px;
    box-sizing: border-box;
    outline: none;
    &::placeholder {
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      color: #B9B9B9;
    }
    :focus, :active {
      outline: none;
    } 
  }
  img {
      position: absolute;
      right: 16px;
      top: 16px;
   }
`
EmailBlock.Bottom = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 15.6px;
  color: #515151;
  text-align: center;
  max-width: 290px;
  margin: 0 auto;
`

export {
  EmailBlock
}