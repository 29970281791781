import Cookies from 'js-cookie'
import md5 from 'crypto-js/md5'
import amplitude from 'amplitude-js'
import {
  APP_BRANCH_FB_URL,
  APP_BRANCH_KNOTS_FB_URL,
  APP_BRANCH_GOOGLE_URL,
  APP_BRANCH_KNOTS_TEST_URL,
  APP_BRANCH_TEST_URL
} from '../constants/finish'

export const clickUrl = (email) => {

  const currentUrl = new URL(window.location)
  const query = Object.fromEntries(currentUrl.searchParams.entries())

  const { aviluna: { ip = {}, eventId = null } = {} } = window

  const metadata = Object.assign({}, {
    fbp: Cookies.get('_fbp') || 'none',
    fbc: Cookies.get('_fbc') || 'none',
    ttclid: Cookies.get('ttclid') || 'none',
    gclid: Cookies.get('gclid') || 'none',
    aviluna_id: Cookies.get('avilunaid') || 'none',
    hashed_aviluna_id: (() => {
      const avilunaId = Cookies.get('avilunaid') || 'none'
      return avilunaId !== null ? md5(avilunaId).toString() : null
    })(),
    event_id: eventId,
    amplitude_id: amplitude.getInstance()._metadataStorage.load().deviceId || null,
    agent: window.navigator.userAgent || null,
    referrer: window.document.referrer || null,
    ip: ip.cloudflare || ip.aviluna || null,
    timestamp: Date.now() / 1000,
    href: (() => {
      if (currentUrl.href && currentUrl.href.length > 1) {
        return currentUrl.href
      }
      return null
    })() || 'none',
    experiments: Cookies.get('experiments') || 'none',
    user_email: email || 'none'
  })

  const landing = (() => {
    let result = (() => {
      let experiment = Cookies.get('experiments') || null
      if (experiment !== null) {
        experiment = experiment.split(',').slice(-1)[0]
        if (experiments.includes(experiment)) {
          return experiment
        } else if (experiment === 'forecast_out') {
          return 'forecast_none'
        } else if (experiment === 'knots_out') {
          return 'knots_none'
        }
      }
      if (currentUrl.pathname && currentUrl.pathname.length > 1) {
        return currentUrl.pathname.slice(1)
      }
      return null
    })()
    if (result === 'variantb') {
      result = 'forecast_email_profile'
    }
    if (result === 'variantc') {
      result = 'forecast_email_personal'
    }
    return result
  })() || 'unknown'

  const params = Object.assign({}, query, {
    cid: query.fbclid || query.ttclid || query.gclid || '',
    fbp: Cookies.get('_fbp') || 'none',
    fbc: Cookies.get('_fbc') || 'none',
    agent: window.navigator.userAgent || null,
    metadata: query.utm_source === 'test' ? null : JSON.stringify(metadata),
    landing: landing
  })

  const url = new URL((() => {
    const isKnotsCustom = landing === 'knots_custom'
    if (query.test === 'true' || query.test === '1') {
      return isKnotsCustom ? APP_BRANCH_KNOTS_TEST_URL : APP_BRANCH_TEST_URL
    } else {
      switch (currentUrl.host) {
        case 'lp.fishboxapp.com':
          return isKnotsCustom ? APP_BRANCH_KNOTS_FB_URL : APP_BRANCH_FB_URL
        case 'lpg.fishboxapp.com':
          return APP_BRANCH_GOOGLE_URL
      }
      return APP_BRANCH_FB_URL
    }
  })())
  Object.entries(params).forEach(entry => {
    const [key, value] = entry
    url.searchParams.append(key, value)
  })
  console.log(`branch url: ${url}`)
  return url

}

const experiments = [
  'forecast_onboarding',
  'forecast_creative',
  'forecast_rework',
  'knots_none',
  'knots_custom'
]