import ff_es from '../assets/fishingForecast/ff_es.png'
import ff_en_1 from '../assets/fishingForecast/ff_en_1.png'
import ff_en_2 from '../assets/fishingForecast/ff_en_2.png'
import ff_en_3 from '../assets/fishingForecast/ff_en_3.png'

export default {
  fishingForecast: {
    title: 'Pronóstico de pesca',
    text: 'Consulta cuándo los peces son más activos y recibe una notificación antes de la siguiente gran actividad',
    imgUrl: ff_es,
    imgUrl1: ff_en_1,
    imgUrl2: ff_en_2,
    imgUrl3: ff_en_3,
  },
  continue: 'Continuar'
}
