// Import the functions you need from the SDKs you need
import { initializeApp, setLogLevel } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getRemoteConfig } from 'firebase/remote-config'


export const FORECAST_PAGE = {
  'forecast_none': 0,
  'forecast_onboarding': 1,
  'forecast_creative': 2,
  'forecast_rework': 3,
}

const firebaseConfig = {
  apiKey: 'AIzaSyDuhJlPpGMwu_EqtPuzjQHPA9ox3cXHxGU',
  authDomain: 'fishbox-landing.firebaseapp.com',
  projectId: 'fishbox-landing',
  storageBucket: 'fishbox-landing.appspot.com',
  messagingSenderId: '90805187719',
  appId: '1:90805187719:web:9574bdd43e9e634383f422',
  measurementId: 'G-Y5YK0X6LZ7',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const remoteConfig = getRemoteConfig()

remoteConfig.settings.minimumFetchIntervalMillis = 100
remoteConfig.settings.fetchTimeoutMillis = 15000

setLogLevel(remoteConfig, 'debug')

remoteConfig.defaultConfig = {
  'experiments': '["knots_none"]',
  'forecast_page': 'none',
  'knots_page': 'none'
}


