import React from 'react'
import { EmailBlock } from './styled'
import logoCIMg from './../../../assets/other/logo_c.svg'
import logoBIMg from './../../../assets/other/logo_b.svg'
import unionIMg from './../../../assets/other/union.svg'
import {clickUrl} from '../../../tracking/links'
import {stepEmailComplete, userStepsBegin} from '../../../amplitude/events'
import { getEmailStatus } from '../../../utils/helper'

const Email = ({variant, data, setEmailAction, email}) => {

  const handleSkipClick = () => {
    const emailStatus = getEmailStatus(email)
    stepEmailComplete('skip', emailStatus)
    userStepsBegin()
    setTimeout(() => {
      window.location.href = clickUrl(email)
    }, 700)
  }

  const handleEmailChange = (value) => {
    setEmailAction(value)
  }

  return (
    <EmailBlock>
      <EmailBlock.Top variant={variant}>
        {
          variant === 'c' ? (
            <img src={logoCIMg} alt="logoc"/>
          ) : <div/>
        }
        <EmailBlock.Top.Skip onClick={handleSkipClick}>
          Skip
        </EmailBlock.Top.Skip>
      </EmailBlock.Top>
      {
        variant === 'b' ? (
          <EmailBlock.Center>
            <img src={logoBIMg} alt="logoB"/>
          </EmailBlock.Center>
        ) : null
      }
      <EmailBlock.Title>
        {data.title}
      </EmailBlock.Title>
      <EmailBlock.Text>
        {data.text}
      </EmailBlock.Text>
      <EmailBlock.Input>
        <input placeholder={data.placeholder} value={email} onChange={(e) => handleEmailChange(e.target.value)}/>
        {
          variant === 'c' ? <img src={unionIMg} alt="union"/> : null
        }
      </EmailBlock.Input>
      {
        variant === 'c' ? (
          <EmailBlock.Bottom>
            {data.bottomText}
          </EmailBlock.Bottom>
        ) : null
      }
    </EmailBlock>
  )
}

export default Email