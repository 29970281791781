import styled from 'styled-components'

const PrivacyBlock = styled.div`

`

PrivacyBlock.Header = styled.div`
  display: flex;
  height: 85px;
  background: #00A1C6;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-bottom: 8px;
  align-items: flex-end;
  justify-content: center;
  font-size: 17px;
  line-height: 23px;
  color: #ffffff;
  font-weight: 400;
`

PrivacyBlock.Header.Back = styled.div`
  position: absolute;
  left: 15px;
  width: 21px;
  height: 16px;
`

PrivacyBlock.Content = styled.div`
  padding: 100px 15px 40px 15px;
`

PrivacyBlock.Content.Block = styled.div`
  margin-bottom: 24px;
`

PrivacyBlock.Title = styled.h2`
  font-weight: 900;
  font-size: 16px;
  line-height: 22px;
  color: #515151;
  margin-bottom: 9px;
  text-align: left;
`

PrivacyBlock.Text = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #515151;
  text-align: left;
`

export { PrivacyBlock }