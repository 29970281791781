import styled from 'styled-components'

const SliderBlock = styled.div`
  overflow: hidden;
  flex: 1;
  .slick-dots {
    position: relative;
    bottom: 0;
    margin-bottom: 1rem;
    height: 1.1875rem;
    li {
    margin: 0;
    width: 1rem;
    height: 0.5rem;
      & button {
      transition: 0.3s;
        &:before {
          content: '';
          width: 0.5rem;
          height: 0.5rem;
          border-radius: 50%;
          background: #00A1C6;
          font-size: 0.5625rem;
          color: #00A1C6;
          opacity: 1;
          transition: 0.1s;
        }
      }
      &.slick-active {
        margin-right: 0.5rem;
        button {
          &:before {
            border-radius: 35%;
            width: 1rem;
          }
        }
      }
    }
  }
  .slick-slide {
    div {
      outline: none;
      border: none;
    }
  }
  @media(max-height: 559px) {
    .slick-dots {
      margin-bottom: 0.3rem;
      margin-top: 0.4375rem;
    }
  }
`

export { SliderBlock }