import Questions from './index.jsx'
import { connect } from 'react-redux'
import { changingQuestion, changingDisplay, changingFinishLoading } from '../../../store/fish/actions'

const mapStateToProps = (state) => {
  return {
    activeStep: state.fish.activeStep,
    activeDisplay: state.fish.activeDisplay,
    isFinishSecond: state.fish.isFinishSecond
  }
}

const mapDispatchToProps = {
  changingQuestion,
  changingDisplay,
  changingFinishLoading
}

export default connect(mapStateToProps, mapDispatchToProps)(Questions)