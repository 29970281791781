import ff_ja from '../assets/fishingForecast/ff_ja.png'

import ff_en_1 from '../assets/fishingForecast/ff_en_1.png'
import ff_en_2 from '../assets/fishingForecast/ff_en_2.png'
import ff_en_3 from '../assets/fishingForecast/ff_en_3.png'

export default {
  fishingForecast: {
    title: '釣りに関連する予報',
    text: '1 日のうちで魚の活性が一番上がる時間帯をチェックし、次の魚の活性が上がるタイミングで通知を受け取りましょう。',
    imgUrl: ff_ja,
    imgUrl1: ff_en_1,
    imgUrl2: ff_en_2,
    imgUrl3: ff_en_3,
  },
  continue: '続ける'
}
