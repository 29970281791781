import styled from 'styled-components'

const FFBox = styled.div`
  flex: 1;
  padding: 0 1.875rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`

FFBox.ImgWrapper = styled.div`
  width: calc(100% + 2.4rem);
  margin-left: calc(-1.2rem);
  margin-right: calc(-1.2rem);
`

FFBox.LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

FFBox.Img = styled.img.attrs(props => ({
  src: props.src || '',
  alt: props.alt || '',
}))`
  width: 100%;
  max-width: 100%;
`
FFBox.TextBox = styled.div`
`

FFBox.Title = styled.h1`
  font-weight: 800;
  color: #000000;
  font-size: 1.875rem;
  line-height: 2rem;
  text-align: center;
  margin-bottom: 0.5rem;
  margin-top: 0;
  word-break: break-word;
  @media(max-height: 669px) {
    margin-bottom: 0;
  }
`

FFBox.Text = styled.p`
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.5rem;
  text-align: center;
  margin: 0.5rem 0 0.625rem 0;
  word-break: break-word;
`

export {
  FFBox
}