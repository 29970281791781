import React from 'preact/compat'
import { Slide } from './styled'
import Email from '../../Email/EmailContainer'

export default function SlideComponent (props) {
  const { slide, variant } = props

  return (
    <Slide>
      {
        variant ? <Email variant={variant} data={slide}/> : (
          <>
            <Slide.Image slide={slide.id} isVariant={slide.isVariant}>
              <img src={slide.imageUrl} alt="slide.title"/>
            </Slide.Image>
            <Slide.Content isVariant={slide.isVariant}>
              <Slide.Title isVariant={slide.isVariant}>{slide.title}</Slide.Title>
              <Slide.Text isVariant={slide.isVariant}>{slide.text}</Slide.Text>
            </Slide.Content>
          </>
        )
      }
    </Slide>
  )
}