import React, { useEffect, useRef, useState } from 'preact/compat'
import SlickSlider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {slides, slideForecast, slideKnots, slidesB, slidesC} from '../../../constants/slides'
import Slide from './Slide/index'
import { SliderBlock } from './styled'
import { firstOpenAction } from '../../../amplitude/events'
import { fbFirstScreenAction } from '../../../fbpixel/events'
import { ttFirstScreenAction } from '../../../ttpixel/events'
import {B_VARIANT_ROUTE, C_VARIANT_ROUTE, FORECAST_ROUTE, KNOTS_ROUTE} from '../../../constants/routes'

export default function Slider (props) {
  const { setActiveSlide, activeSlide, route } = props
  const sliderRef = useRef()
  const [currentSlides, setCurrentSlides] = useState([])


  const sliderParams = {
    dots: true,
    infinite: false,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (current) => {
      setActiveSlide(current + 1)
    }
  }

  const checkRoute = (route) => {
    switch (route) {
      case FORECAST_ROUTE:
        setCurrentSlides(slideForecast)
        break
      case KNOTS_ROUTE:
        setCurrentSlides(slideKnots)
        break
      case B_VARIANT_ROUTE:
        setCurrentSlides(slidesB)
        break
      case C_VARIANT_ROUTE:
        setCurrentSlides(slidesC)
        break
      default:
        setCurrentSlides(slides)
    }
  }

  useEffect(() => {
    if (route === FORECAST_ROUTE || route === B_VARIANT_ROUTE || route === C_VARIANT_ROUTE) {
      fbFirstScreenAction()
      ttFirstScreenAction()
      checkRoute(route)
    } else {
      firstOpenAction()
      fbFirstScreenAction()
      ttFirstScreenAction()
      checkRoute(route)
    }


  }, [])

  useEffect(() => {
    sliderRef.current.slickGoTo(activeSlide - 1)
  }, [activeSlide])



  return (
    <SliderBlock>
      <SlickSlider {...sliderParams} ref={sliderRef}>
        {
          currentSlides.map((slide) => {
            return (
              <div key={slide.id}>
                <Slide slide={slide} variant={slide.variant}/>
              </div>
            )
          })
        }
      </SlickSlider>
    </SliderBlock>
  )
}