import React, {useEffect} from 'preact/compat'
import './App.css'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import {
  QUIZ_ROUTE,
  BASE_ROUTE,
  SLIDER_ROUTE,
  FORECAST_ROUTE,
  KNOTS_ROUTE,
  PRIVACY_ROUTE,
  B_VARIANT_ROUTE, C_VARIANT_ROUTE
} from './constants/routes'
import Fishing from './components/Fishing/FishingContainer'
import Privacy from './components/Privacy/Privacy'

function App() {
  useEffect(() => {
    const setAppHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
    setAppHeight()
    window.addEventListener('resize', setAppHeight)
    return () => window.removeEventListener('resize', setAppHeight)
  }, [])

  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path={BASE_ROUTE} render={() => <Fishing route={BASE_ROUTE}/>}/>
          <Route exact path={SLIDER_ROUTE} render={() => <Fishing route={SLIDER_ROUTE}/>}/>
          <Route exact path={QUIZ_ROUTE} render={() => <Fishing route={QUIZ_ROUTE}/>}/>
          <Route exact path={FORECAST_ROUTE} render={() => <Fishing route={FORECAST_ROUTE}/>}/>
          <Route exact path={B_VARIANT_ROUTE} render={() => <Fishing route={B_VARIANT_ROUTE}/>}/>
          <Route exact path={C_VARIANT_ROUTE} render={() => <Fishing route={C_VARIANT_ROUTE}/>}/>
          <Route exact path={KNOTS_ROUTE} render={() => <Fishing route={KNOTS_ROUTE}/>}/>
          <Route exact path={PRIVACY_ROUTE} render={() => <Privacy />}/>
        </Switch>
        <Route exact path='/' render={({ location }) => <Redirect to={`${BASE_ROUTE}${location.search ? location.search : '' }`} />}/>
      </Router>
    </div>
  )
}

export default App
