import Cookies from 'js-cookie'
import { emailRegExp } from '../constants/regexp'

export function checkCookiesFunction (value, key) {
  let cookiesString = Cookies.get(key)
  if (cookiesString !== undefined) {
    if (cookiesString.includes(value)) {
      return
    } else {
      cookiesString = `${cookiesString},${value}`
      Cookies.set(key, cookiesString)
    }
  } else {
    Cookies.set(key, value)
  }
}

export function getEmailStatus (email) {
  if (email === '') {
    return 'empty'
  }
  if (String(email).toLowerCase().match(emailRegExp)) {
    return 'correct'
  } else {
    return 'incorrect'
  }
}