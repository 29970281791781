export const points = [
  {
    id: 1,
    text: 'Preparing fishing points'
  },
  {
    id: 2,
    text: 'Solunar calculation'
  },
  {
    id: 3,
    text: 'Analysis of fish activity'
  },
  {
    id: 4,
    text: 'Preparing a personal program'
  },
  {
    id: 5,
    text: 'Preparing a weather forecast for a selected place'
  },
]

export const firstOptionsObject = [
  {
    startPercent: 0,
    endPercent: 10,
    delay: 0,
    duration: 1600,
  },
  {
    startPercent: 10,
    endPercent: 33,
    delay: 400,
    duration: 2400,
  },
  {
    startPercent: 33,
    endPercent: 64,
    delay: 0,
    duration: 2000,
  }
]

export const secondOptionsObject = [
  {
    startPercent: 64,
    endPercent: 87,
    delay: 0,
    duration: 1000,
  },
  {
    startPercent: 87,
    endPercent: 95,
    delay: 0,
    duration: 1400,
  },
  {
    startPercent: 95,
    endPercent: 100,
    delay: 0,
    duration: 1600,
  }
]

export const APP_BRANCH_FB_URL = 'https://fishbox.app.link/gFz2ed2Bfeb?%243p=a_custom_894636999852067240'
export const APP_BRANCH_KNOTS_FB_URL = 'https://fishbox.app.link/PQnkQisetzb?%243p=a_custom_894636999852067240'
export const APP_BRANCH_GOOGLE_URL = 'https://fishbox.app.link/Cq3l99xLFwb?%243p=a_custom_1144014765859367502'
export const APP_BRANCH_TEST_URL = 'https://fishbox.test-app.link/IimOQnVk1wb?%243p=a_custom_1148725006993173261'
export const APP_BRANCH_KNOTS_TEST_URL = 'https://fishbox.test-app.link/vLsNcFlntzb?%243p=a_custom_1148725006993173261'
