export const fbFirstScreenAction = () => {
  if (window.fbq != null) { 
    window.fbq('track', 'ViewContent')
  }
}

export const fbGetStartedScreenAction = () => {
  if (window.fbq != null) { 
    window.fbq('track', 'AddToWishlist')
  }
}

export const fbCompleteScreenAction = () => {
  if (window.fbq != null) { 
    window.fbq('track', 'AddToCart')
  }
}

export const fbOpenAppStoreAction = () => {
  if (window.fbq != null) { 
    window.fbq('track', 'AddPaymentInfo')
  }
}

