import ff_en from '../assets/fishingForecast/ff_en.png'

import ff_en_1 from '../assets/fishingForecast/ff_en_1.png'
import ff_en_2 from '../assets/fishingForecast/ff_en_2.png'
import ff_en_3 from '../assets/fishingForecast/ff_en_3.png'

export default {
    fishingForecast: {
        title: 'Fishing forecast',
        text: 'Check when fish the most active during the day and get notified before next high fish activity',
        imgUrl: ff_en,
        imgUrl1: ff_en_1,
        imgUrl2: ff_en_2,
        imgUrl3: ff_en_3,
    },
    continue: 'Continue'
}
