import React, { useEffect }  from 'preact/compat'
import {FFBox} from './styled'
import {I18n} from 'react-redux-i18n'
import { fbFirstScreenAction } from '../../../fbpixel/events'
import { ttFirstScreenAction } from '../../../ttpixel/events'
import { setVersion } from '../../../amplitude/events'
import { FadeLoader } from 'react-spinners'

const FishingForecast = (props) => {
  const { loading, forecastView } = props

  useEffect(() => {
    // firstOpenAction()
    setVersion()
    fbFirstScreenAction()
    ttFirstScreenAction()
  }, [])

  const setForecastView = (view) => {
    switch (view) {
      case 0:
        return <FFBox.Img src={I18n.t('fishingForecast.imgUrl')} />
      case 1:
        return <FFBox.Img src={I18n.t('fishingForecast.imgUrl1')} />
      case 2:
        return <FFBox.Img src={I18n.t('fishingForecast.imgUrl2')} />
      case 3:
        return <FFBox.Img src={I18n.t('fishingForecast.imgUrl3')} />
    }
  }

  return <FFBox>
    <FFBox.ImgWrapper>
      {
        loading ? (
          <FFBox.LoaderWrapper>
          <FadeLoader color={'#C4C4C4'} radius={6} margin={1} width={6} height={17}/>
        </FFBox.LoaderWrapper>
        ) : (
          setForecastView(forecastView)
        )
      }
    </FFBox.ImgWrapper>
    <FFBox.TextBox>
      <FFBox.Title>
        {I18n.t('fishingForecast.title')}
      </FFBox.Title>
      <FFBox.Text>
        {I18n.t('fishingForecast.text')}
      </FFBox.Text>
    </FFBox.TextBox>
    </FFBox>
}

export default FishingForecast