import amplitude from 'amplitude-js'
import Cookies from 'js-cookie'
import {
  B_VARIANT_ROUTE,
  BASE_ROUTE, C_VARIANT_ROUTE,
  FORECAST_ROUTE,
  KNOTS_ROUTE,
  QUIZ_ROUTE,
  SLIDER_ROUTE
} from '../constants/routes'
import {VERSION} from '../constants/data'

export const setVersion = () => {
  return amplitude.getInstance().setVersionName(VERSION)
}

export const experimentsAppliedAction = (experiments) => {
  return amplitude.getInstance().logEvent('experiments applied', {
    experiments: experiments
  })
}

export const userPropertyPreInsertProp = (prop, value) => {
  let identify = new amplitude.Identify().preInsert(prop, value)
  return amplitude.identify(identify)
}

export const configRequestCompletedAction = (isSuccess, e, time) => {
  return amplitude.getInstance().logEvent('config request completed', {
    success: isSuccess,
    error: e,
    'time-ms': time
  })
}

export const stepEmailComplete = (result, email) => {
  return amplitude.getInstance().logEvent('Step_email_completed', {
    'result': result,
    'email': email
  })
}

export const firstOpenAction = () => {
  return amplitude.getInstance().logEvent('First_Open_action')
}

export const userStepsBegin = (step) => {

  const { aviluna: { ip = {} } = {} } = window
  if (ip) {
    const { cloudflare = null, aviluna = null } = ip
    const identify = new amplitude.Identify()
    .set('ip-info', {
      'cloudflare': cloudflare,
      'aviluna': aviluna,
      'same': aviluna === cloudflare
    })
    amplitude.getInstance().identify(identify)
  }

  return amplitude.getInstance().logEvent('User_Steps_begin', { 'result': step })
}

export const userGetStarted = () => {
  return amplitude.getInstance().logEvent('User_Get_started')
}

export const createAnswerEvent = (question, activeIndex) => {
  const event = question.amplitudeEvent
  let result
  if(Array.isArray(activeIndex) === true) {
    result = []
    activeIndex.forEach((index) => {
      const answerItem = question.answers[index].short
      result.push(answerItem)
    })
  } else {
    result = question.answers[activeIndex].short
  }
  return amplitude.getInstance().logEvent(event, { 'result': result })
}

export const stepPersonalizationView = () => {
  return amplitude.getInstance().logEvent('Step_Personalization_view')
}

export const stepPersonalizationComplete = () => {
  return amplitude.getInstance().logEvent('Step_Personalization_complete')
}

export const onboardingLoading = (route, onboardingName) => {
  let onboarding
  switch (route) {
    case BASE_ROUTE:
      onboarding = 'carouselQuiz'
      break
    case SLIDER_ROUTE:
      onboarding = 'carousel'
      break
    case QUIZ_ROUTE:
      onboarding = 'quiz'
      break
    case FORECAST_ROUTE:
      onboarding = 'forecast'
      break
    case B_VARIANT_ROUTE:
      onboarding = 'forecast_email_profile'
      break
    case C_VARIANT_ROUTE:
      onboarding = 'forecast_email_personal'
      break
    case KNOTS_ROUTE:
      onboarding = 'knots'
  }
  if (onboardingName) {
    onboarding = onboardingName
  }
  const identify = new amplitude.Identify()
    .set('Onboarding', onboarding)
    .set('fbp', Cookies.get('_fbp') || 'none')
    .set('fbc', Cookies.get('_fbc') || 'none')
    .set('ttclid', Cookies.get('ttclid') || 'none')
    .set('gclid', Cookies.get('gclid') || 'none')
    .set('href', (() => {
      const currentUrl = new URL(window.location)
      if (currentUrl.href && currentUrl.href.length > 1) {
        return currentUrl.href
      }
      return null
    })() || 'none')
  return amplitude.getInstance().identify(identify)
}

