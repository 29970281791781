import React, { useState, useEffect } from 'preact/compat'
import { Animated, Finish } from './styled'
import { points, firstOptionsObject, secondOptionsObject } from '../../../constants/finish'
import { clickUrl } from '../../../tracking/links'
import waveBig from './../../../assets/other/wave_big.svg'
import waveSmall from './../../../assets/other/wave_small.svg'
import float from './../../../assets/other/float_inerit.svg'
import tick from './../../../assets/other/tick.svg'
import { stepPersonalizationView, stepPersonalizationComplete } from '../../../amplitude/events'
import { fbCompleteScreenAction, fbOpenAppStoreAction } from '../../../fbpixel/events'
import { ttCompleteScreenAction, ttOpenAppStoreAction } from '../../../ttpixel/events'

export default function FinishComponent (props) {
  const { changingDisplay, changingQuestion, isFinishSecond } = props

  const [percent, setPercent] = useState(isFinishSecond ? 65 : 0)
  const [bgHeight, setBgHeight] = useState(0)

  const middlePercent = 64

  useEffect(() => {
    isFinishSecond === false && stepPersonalizationView()
    isFinishSecond === true ? PrecentageChanging(middlePercent, secondOptionsObject) : PrecentageChanging(middlePercent, firstOptionsObject)
  }, [])

  useEffect(() => {
    setBgHeight(percent)
    if(percent === middlePercent) {
      changingQuestion(5, 5)
      setTimeout(() => changingDisplay('questions'), 1000)
    }
    if (percent === 100) {
      fbCompleteScreenAction()
      ttCompleteScreenAction()
    }
  }, [percent])

  const complete = () => {
    fbOpenAppStoreAction()
    ttOpenAppStoreAction()
    stepPersonalizationComplete()
    window.location.href = clickUrl()  
  }

  const PrecentageChanging = (middlePercent, optionsObject) => {
    let activeStep = 0
    let stepsLength = optionsObject.length
    let myPercent = percent
    
    const next = () => {
      if(activeStep >= stepsLength) {
        return false
      } else {
        const item = optionsObject[activeStep]
        const interval = item.duration/(item.endPercent - item.startPercent)
        if(item.delay !== 0) {
          setTimeout(() => {
            render(item, interval)
          }, item.delay)
        } else {
          render(item, interval)
        }
      }
    }
    const render = (item, interval) => {
      if(myPercent <= item.endPercent) {
        let int = setInterval(() => {
          myPercent++
          setPercent(myPercent)
          if(myPercent >= item.endPercent) {
            clearInterval(int)
            activeStep++
            next()
          }
        }, interval)
      }
    }
    next()
  }

  // formulas to percent calculating (points)
  const formula =  (index) => {
    return (100/points.length)* (index + 1) - (100/points.length) + 5
  }
  // formulas to percent calculating (three points)
  const formulaPoints =  (index) => {
    return (100/points.length)* (index + 1) + 5
  }

  // animated dots
  const animatedDots = () => {
    return (
      <span>
        <Animated>
          <Animated.Dot className={'first'}>.</Animated.Dot>
          <Animated.Dot className={'second'}>.</Animated.Dot>
          <Animated.Dot className={'third'}>.</Animated.Dot>
        </Animated>
      </span>
    )
  }


  return (
    <Finish>
      <Finish.Title>
        {
          percent !== 100
            ? <span>
                Personalizing your profile
              { animatedDots() }
              </span>
            : 'Your profile is ready'
        }
      </Finish.Title>
      <Finish.Icon>
        <Finish.Wave>
          <Finish.Wave.Big>
            <img src={waveBig} alt="wave big"/>
          </Finish.Wave.Big>
          <Finish.Wave.Small>
            <img src={waveSmall} alt="wave small"/>
          </Finish.Wave.Small>
        </Finish.Wave>
        <Finish.Float className={ percent !== 0 && percent !== 65 ? 'visible' : '' }>
          <Finish.Float.Bg>
            <Finish.Float.Color style={{'height': `${bgHeight}%`}}>
              <Finish.Float.Blue/>
              <Finish.Float.Red/>
              <Finish.Float.Blue.Bottom/>
            </Finish.Float.Color>
          </Finish.Float.Bg>
          <Finish.Float.Background>
            <img src={float} alt="float"/>
          </Finish.Float.Background>
        </Finish.Float>
      </Finish.Icon>
      <Finish.Precent>
        <span className="number">{percent}</span>
        <span>%</span>
      </Finish.Precent>
      <Finish.Items>
        {
          points.map((point, index) => {
            return (
              <Finish.Item key={index} className={ percent > formula(index) ? 'visible' : ''} >
                <Finish.Loading className={ isFinishSecond && index < 3 ? 'loading-ready' : 'loading'}>
                  <img src={tick} alt="tick"/>
                </Finish.Loading>
                <Finish.Text>
                  <span>
                    {point.text}
                    { percent < formulaPoints(index)
                      ? ( percent === 100 ? '' : animatedDots() ) : '' }
                  </span>
                </Finish.Text>
              </Finish.Item>
            )
          })
        }
      </Finish.Items>
        <Finish.Button onClick={complete} className={percent === 100 ? 'visible' : ''}>
          <Finish.Button.Block>
            Let&apos;s start
          </Finish.Button.Block>
        </Finish.Button>
    </Finish>
  )
}