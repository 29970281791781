export const privacyContent = [
  {
    title: 'Privacy Policy',
    texts: [
      'Last update: 17th June 2017',
      'Thank you for using Fishbox mobile application (hereinafter "Application").',
      'Please read this Privacy Policy carefully before agreeing or downloading or using the Application. In order to use the Application, you must first accept this Privacy Policy. By clicking the accept button or downloading or using the Application, you are entering into and agreeing to be bound by this agreement.',
      'If you do not or cannot accept this Privacy Policy, you are not permitted to use the application. In such case do not download or use the Application.'
    ]
  },
  {
    title: '1. Analytics',
    texts: [
      'We partner with certain third parties (providers of analytics services) to collect information about how you use the Applications (e.g. average time of use, number of sessions, clicks, crash logs). It does not collect any private information about you or your device content in a way to identify an individual user. Use of analytics services is essential in order to improve the app quality and workflow.',
      'In particular, our Services use Google Analytics, Google Firebase, Google Fabric to help collect and analyze certain information for the purposes discussed above.'
    ]
  },
  {
    title: '2. Third Party Services',
    texts: [
      'Third Party Services used in the Application may collect additional data for their own purposes (e.g. to contextualize and personalize the ads in its own advertising network).',
      'These third parties may use device identifiers, such as IDFA or Advertising ID, and other user data available on the device (e.g. location, language) to collect information about you.',
      'Third Party Services and some other references / links to other websites are not operated or controlled by us. The policies and procedures described here do not apply to Third Party Services. The links from our Application do not imply our review or endorsement of Third Party Services. We suggest contacting those sites directly for information on their privacy policies.'
    ]
  },
  {
    title: '3. Reliability',
    texts: [
      'You are responsible for backing up, to your own computer or other device, any important data stored or accessed via the Application (e.g. locations, trolling, trotlines, catches, photos, charts). We do not guarantee or warrant that any content you may store or access through the Application will not be subject to inadvertent damage, corruption or loss.',
    ]
  },
  {
    title: '4. Security',
    texts: [
      'We will use commercially reasonable efforts to safeguard the confidentiality of your personal information. However, we cannot be held liable for any loss of or disclosure of data or any losses or damages incurred due to errors in transmission or unauthorized or unlawful acts of third parties or your decision to disclose your personal information.',
    ]
  },
  {
    title: '5. How to request data deletion',
    texts: [
      'You have a right to have some of the personal information that we hold about you rectified or deleted. Should you wish to have any information about you rectified or deleted, please contact us at <a href="mailto:fishboxapp@gmail.com" style="">fishboxapp@gmail.com</a>. Where we delete personal information about you, we may still retain some or all of that information for other purposes such as maintaining financial records, protecting or enforcing legal rights, or for technical reasons such as maintaining our database integrity. We may also retain your information in an anonymized form.',
    ]
  },
  {
    title: '6. Modification or Amendment',
    texts: [
      'Company may modify or amend the terms of this Privacy Policy at its sole discretion at any time without prior notice, by posting a copy of the modified or amended Privacy Policy on the Company website «https://fishboxapp.com». You will be deemed to have agreed to any such modification or amendment by your decision to continue using the Application following the date in which the modified or amended Agreement is posted on the Company website. You are responsible to regularly reviewing the Privacy Policy.',
    ]
  },
  {
    title: '7. Contact Information',
    texts: [
      'If you have any questions or concerns about this Privacy Policy, please feel free to contact us at any time on our email address: fishboxapp@gmail.com.',
    ]
  },
]