import ff_de from '../assets/fishingForecast/ff_de.png'
import ff_en_1 from '../assets/fishingForecast/ff_en_1.png'
import ff_en_2 from '../assets/fishingForecast/ff_en_2.png'
import ff_en_3 from '../assets/fishingForecast/ff_en_3.png'

export default {
  fishingForecast: {
    title: 'Fangprognose',
    text: 'Überprüfe, wann Fische am aktivsten sind und erhalte Benachrichtigungen bei Zunahme der Fischaktivität',
    imgUrl: ff_de,
    imgUrl1: ff_en_1,
    imgUrl2: ff_en_2,
    imgUrl3: ff_en_3,
  },
  continue: 'Weiter'
}
