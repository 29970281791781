export const questions = [
  {
    id: 1,
    isCheckbox: false,
    question: 'What is your fishing skills level?',
    answers: [
      { text: 'I’m a newbie', short: 'newbie'},
      { text: 'I’m amateur', short: 'amateur'},
      { text: 'I’m an experienced angler', short: 'experienced'}
    ],
    amplitudeEvent: 'Step_Skills_complete'
  },
  {
    id: 2,
    isCheckbox: true,
    question: 'What information do you want to get?',
    answers: [
      { text: 'New spots to fish', short: 'new spots'},
      { text: 'The forecasts in my favorite fishing spots', short: 'forecast in my spots'},
      { text: 'The weather forecast for new places I\'m going to', short: 'forecast new place'},
      { text: 'Stats about my catches', short: 'catches'},
      { text: 'Instructions for fishing knots', short: 'knots'},
      { text: 'Lure data', short: 'lure'}

    ],
    amplitudeEvent: 'Step_Goal_complete'
  },
  {
    id: 3,
    isCheckbox: true,
    question: 'What information is essential for you when preparing for fishing? ',
    answers: [
      { text: 'Hourly weather forecast at the fishing spot', short:'hourly weather'},
      { text: 'Tides/waves', short: 'tides '},
      { text: 'Moon and sun information', short: 'solunar'},
      { text: 'Information about fishing activity - fishing time', short: 'fishing activity'},
      { text: 'What types of bait I need', short: 'bait'},
      { text: 'Types of fish available near me', short: 'type of fish'},
      { text: 'Fishing regulations', short: 'fishing regulations'},
      { text: 'Different ways to fish', short: 'different ways to fish'},
      { text: 'Where to buy/rent fishing equipment', short: 'fishing equipment'}
    ],
    amplitudeEvent: 'Step_Preparing_complete'
  },
  {
    id: 4,
    isCheckbox: false,
    question: 'Where do you fish?',
    answers: [
      { text: 'Freshwater', short: 'freshwater'},
      { text: 'Salwater', short: 'salwater'},
      { text: 'Both', short: 'both'}
    ],
    amplitudeEvent: 'Step_Water_complete'
  },
  {
    id: 5,
    isCheckbox: false,
    question: 'How often do you go fishing?',
    answers: [
      { text: 'Once a week', short: 'once a week'},
      { text: 'Several times a week', short: 'several times a week'},
      { text: 'Once a month', short: ' once a month'},
      { text: 'Several times a month', short: 'several times a month'}
    ],
    amplitudeEvent: 'Step_Frequency_complete'
  },
]