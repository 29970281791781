import Finish from './index.jsx'
import { connect } from 'react-redux'
import { changingQuestion, changingDisplay } from '../../../store/fish/actions'

const mapStateToProps = (state) => {
  return {
    activeStep: state.fish.activeStep,
    isFinishSecond: state.fish.isFinishSecond,
    location: state.router.location
  }
}

const mapDispatchToProps = {
  changingQuestion,
  changingDisplay
}

export default connect(mapStateToProps, mapDispatchToProps)(Finish)