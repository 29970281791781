export const SLIDER_ROUTE = '/slider'
export const QUIZ_ROUTE = '/quiz'
export const BASE_ROUTE = '/base'
export const FORECAST_ROUTE = '/forecast'
export const KNOTS_ROUTE = '/knots'
export const PRIVACY_ROUTE = '/privacy'
export const A_VARIANT_ROUTE = '/varianta'
export const B_VARIANT_ROUTE = '/variantb'
export const C_VARIANT_ROUTE = '/variantc'

export const EXTERNAL_TERMS = 'https://fishboxapp.com/terms'
export const EXTERNAL_PRIVACY = 'https://fishboxapp.com/privacy'