import styled, { keyframes } from 'styled-components'
import { questionsGadient } from '../../../constants/slides'

const Question = styled.div`
  position: relative;
  overflow: hidden;
`

Question.StepsWrapper = styled.div`
  position: sticky;
  top: 0;
  background: ${questionsGadient};
  padding-left: 1.875rem;
  padding-right: 1.875rem;
`

Question.Steps = styled.div`
  height: 4.375rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 0.625rem;
`

Question.StepsItem = styled.div`
  height: 0.25rem;
  width: auto;
  flex-grow: 1;
  margin-right: 0.5rem;
  border-radius: 0.625rem;
  background: #B9E2EB;
  transition: 0.3s;
  &:last-child {
    margin-right: 0;
  }
  &.active {
    background: #00A4CA;
  }
`

const slideAnimation = keyframes`
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  45% {
    transform: translateX(-100%);
    opacity: 0;
  }
  70% {
    transform: translateX(50%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`

Question.Wrapper = styled.div`
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  //transition: 0.3s;
  animation-fill-mode: forwards;
  &.animated {
    animation: 1s ${slideAnimation} ease-in-out ;
  }

`

Question.Title = styled.div`
  height: 11.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  font-size: 1.625rem;
  line-height: 2.1875rem;
  color: #00394A;
`

Question.Tooltip = styled.div`
  margin-bottom: 0.5rem;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #6DA8B5;
  text-align: left;
  font-weight: 400;
`

Question.Answers = styled.div`
padding-bottom: 2rem;
  &.active {
      padding-bottom: 8.5625rem;
  }
`


Question.Button = styled.div`
  overflow: hidden;
  max-height: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  //margin-top: 0.875rem;
  background: #F3FAFC;
  transition: 0.3s;
  &.active {
    padding-top: 1.4375rem;
    padding-bottom: 3.125rem;
    max-height: 8.5625rem;
    box-shadow: 0 0 10px rgba(0, 111, 135, 0.2);
  }
`

Question.Continue = styled.div`
  height: 4rem;
  flex-grow: 1;
  border-radius: 0.1875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 2.0625rem;
  color: #ffffff;
  background: #00A4CA;
  cursor: pointer;
  margin-left: 1.875rem;
  margin-right: 1.875rem;
`

const AnswerDefault = styled.div`
  padding: 1rem;
  background: #ffffff;
  border-radius: 0.1875rem;
  box-shadow: 0 2px 10px rgba(0, 111, 135, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.625rem;
  transition: 0.3s;
  box-sizing: border-box;
  border: 1px solid rgba(255,255,255,0);
  cursor: pointer;
  path {
    transition: 0.3s;
    stroke: #00394A;
  }
  &.active {
    background: #24BDE2;
    box-shadow: 0 2px 10px rgba(0, 111, 135, 0.2);
    div {
      color: #ffffff;
      transition: 0.3s;
    }
    path {
      stroke: #ffffff;
    }
  }
  &.active-checkbox {
    border: 1px solid #24BDE2;
    .checkbox {
      border-color: #24BDE2;
      background: #24BDE2;
    }
  }
`

AnswerDefault.Text = styled.div`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #00394A;
  text-align: left;
  width: 85%;
`

AnswerDefault.Icon = styled.div`

`

AnswerDefault.Checkbox = styled.div`
  width: 1.25rem;
  height: 1.2rem;
  border: 1px solid #C0D1D9;
  border-radius: 50%;
  transition: 0.3s;
  path {
    stroke: #ffffff;
  }
`

export { Question, AnswerDefault, slideAnimation }