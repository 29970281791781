export const CHANGING_DISPLAY = 'CHANGING_DISPLAY'
export const CHANGING_QUESTION = 'CHANGING_QUESTION'
export const CHANGING_FINISH_LOADING = 'CHANGING_FINISH_LOADING'
export const SET_ACTIVE_SLIDE = 'SET_ACTIVE_SLIDE'
export const CHANGING_ANIMATION = 'CHANGING_ANIMATION'
export const SET_ACTIVE_ROUTE = 'SET_ACTIVE_ROUTE'
export const SET_EMAIL = 'SET_EMAIL_FUNCTION'
export const SET_KNOTS_PAGE = 'SET_KNOTS_PAGE'

export function changingDisplay (display) {
    return (dispatch) => {
      dispatch({
        type: CHANGING_ANIMATION,
        payload: true
      })
      setTimeout(() => {
        dispatch({
          type: CHANGING_DISPLAY,
          payload: display
        })
      }, 500)
      setTimeout(() => {
        dispatch({
          type: CHANGING_ANIMATION,
          payload: false
        })
      }, 2000)

    }
}

export function setActiveRoute (route) {
  return (dispatch) => {
    dispatch({
      type: SET_ACTIVE_ROUTE,
      payload: route
    })
  }
}

export function changingQuestion (num, length) {
  if(num <= length ) {
    return (dispatch) => {
      dispatch({
        type: CHANGING_QUESTION,
        payload: num
      })
    }
  } else {
    return false
  }
}

export function changingFinishLoading () {
  return (dispatch) => {
    dispatch({
      type: CHANGING_FINISH_LOADING,
      payload: true
    })
  }
}

export function setActiveSlide (slide) {
  return (dispatch) => {
    dispatch({
      type: SET_ACTIVE_SLIDE,
      payload: slide
    })
  }
}

export function setEmailAction (email) {
  return (dispatch) => {
    dispatch({
      type: SET_EMAIL,
      payload: email
    })
  }
}

export function setKnotsPage (page) {
  return (dispatch) => {
    dispatch({
      type: SET_KNOTS_PAGE,
      payload: page
    })
  }
}