import styled from 'styled-components'
import {  slideAnimation } from './Questions/styled'
import { gradient, questionsGadient } from '../../constants/slides'

const Wrapper = styled.div`
  max-width: 50rem;  
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  transition: 0.3s;
  box-sizing: border-box;
  min-height: inherit;
  animation-fill-mode: forwards;
  overflow: hidden;
  padding: 0 0 1rem 0;
  @media(min-width: 768px){
    padding: 0;
  }
  &.animated {
    animation: 1s ${slideAnimation} ease-in-out ;
  }
 `
  // ${props => {
  //   let height
  //   let justifyContent
  //   switch(props.activeDisplay) {
  //     case 'slider':
  //       height = 'none'
  //       justifyContent = 'space-between'
  //       break
  //     case 'intro':
  //       height = '100vh'
  //       justifyContent = 'space-between'
  //       break
  //     case 'questions':
  //       height = 'none'
  //       break
  //     case 'finish':
  //       height = '100vh'
  //       justifyContent = 'space-between'
  //   }
  //   return `max-height: ${height}; justify-content: ${justifyContent}`
  // }};
  //
  // padding-bottom: ${props => {
  //   return props.activeDisplay === 'intro' ? '4.5rem !important' : '5rem'
  // }};


  
  // min-height: ${props => {
  // let height
  // switch (props.activeDisplay) {
  //   case 'questions':
  //     height = '100vh'
  //     break
  //   case 'slider':
  //     height = 'calc(100vh - 4.2rem)'
  //     break
  //   case 'intro':
  //     height =  'calc(100vh - 4.2rem)'
  //     break
  //   case 'finish':
  //     height =  'calc(100vh - 4.2rem)'
  //   }
  //   return height
  // }};
  // @media only screen
  //   and (orientation: landscape) {
  //   max-height: none;
  // }
  //
  // // iphone 11 pro max 12
  // @media only screen
  //   and (max-device-height : 896px)
  //   and (orientation : portrait) {
  //     padding-bottom: 10rem;
  //     max-height: none;
  //     min-height: 100vh;
  // }
  //
  // // iphone 12 pro
  // @media only screen
  //   and (max-device-height : 844px)
  //   and (orientation : portrait) {
  //     padding-bottom: 9rem;
  // }
  //
  // // iphone 11 pro 11
  // @media only screen
  //   and (max-device-height : 818px)
  //   and (orientation : portrait) {
  //     padding-bottom: 8rem;
  // }
  //
  // // iphone 8 plus
  // @media only screen
  //   and (max-device-height : 736px)
  //   and (orientation : portrait) {
  //     padding-bottom: 8rem;
  //     max-height: none;
  //     min-height: 100vh;
  //     height: auto !important;
  // }
  //
  //
  // @media only screen
  //   and (max-device-height : 667px)
  //   and (orientation : portrait) {
  //     padding-bottom: 4.5rem;
  //     min-height: ${props => props.activeDisplay === 'questions' ? '100vh' : 'calc(100vh - 2.5rem)'};
  // }
  //
  // // iphone 5
  // @media only screen
  //   and (max-device-height : 568px)
  //   and (orientation : portrait) {
  //     padding-bottom: 3.5rem;
  //     max-height: none;
  //     min-height: 100vh;
  //     height: auto !important;
  // }

Wrapper.Bg = styled.div`
  //min-height: 100vh;
  min-height: var(--app-height);
  box-sizing: border-box;
  background: ${props => {
  let color
  switch(props.activeDisplay) {
    case 'slider':
      // color = 'rgba(229, 229, 229, 0.3)'
      color = 'white'
      break
    case 'intro':
      color = gradient
      break
    case 'questions':
      color = questionsGadient
      break
    case 'finish':
      color = questionsGadient
  }
  return color
}};
  

`

Wrapper.ButtonBlock = styled.div`
  padding-left: 1.875rem;
  padding-right: 1.875rem;
`

Wrapper.Button = styled.div`
  height: 3.4rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: ${props => props.isForecastRoute ? '8px' : '3px'};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  font-size: ${props => props.isForecastRoute ? '22px' : '1.5rem'} ;
  line-height: 2rem;
  color: #ffffff;
  background: #00A1C6;
  cursor: pointer;

`

Wrapper.Privacy = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  //height: 40px;
  a {
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  text-decoration-line: underline;
  color: #9A9A9A;
  }
  
  span {
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: #A0A0A0;
  }
`

Wrapper.Privacy = styled.div`
  margin-top: 16px;
  //margin-bottom: 16px;
  height: 40px;
  box-sizing: border-box;
  a {
    font-size: 10px;
    line-height: 14px;
    text-align: center;
    text-decoration-line: underline;
    color: #9A9A9A;
  }
  span {
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    color: #A0A0A0;
  }
`

export { Wrapper }