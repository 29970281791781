import {
  CHANGING_DISPLAY,
  CHANGING_QUESTION,
  CHANGING_FINISH_LOADING,
  SET_ACTIVE_SLIDE,
  CHANGING_ANIMATION,
  SET_ACTIVE_ROUTE,
  SET_EMAIL,
  SET_KNOTS_PAGE
} from './actions'

const ACTION_HANDLERS = {
  [CHANGING_DISPLAY]: (state, action) => {
    return { ...state, activeDisplay: action.payload }
  },
  [CHANGING_QUESTION]: (state, action) => {
    return { ...state, activeStep: action.payload }
  },
  [CHANGING_FINISH_LOADING]: (state, action) => {
    return { ...state, isFinishSecond: action.payload }
  },
  [SET_ACTIVE_SLIDE]: (state, action) => {
    return { ...state, activeSlide: action.payload }
  },
  [CHANGING_ANIMATION]: (state, action) => {
    return { ...state, animationIsActive: action.payload }
  },
  [SET_ACTIVE_ROUTE]: (state, action) => {
    return { ...state, activeRoute: action.payload }
  },
  [SET_EMAIL]: (state, action) => {
    return { ...state, email: action.payload }
  },
  [SET_KNOTS_PAGE]: (state, action) => {
    return { ...state, knotsPage: action.payload }
  },
}

// view levels: slider, questions, finish

const initialState = {
  activeRoute: '',
  activeDisplay: '',
  activeStep: 1,
  activeSlide: 1,
  isFinishSecond: false,
  animationIsActive: false,
  email: '',
  knotsPage: ''
}

export default (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
