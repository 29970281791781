import ff_pt from '../assets/fishingForecast/ff_pt.png'

import ff_en_1 from '../assets/fishingForecast/ff_en_1.png'
import ff_en_2 from '../assets/fishingForecast/ff_en_2.png'
import ff_en_3 from '../assets/fishingForecast/ff_en_3.png'

export default {
  fishingForecast: {
    title: 'Previsão de pesca',
    text: 'Verifique se os peixes são os mais ativos durante o dia e seja notificado antes da próxima atividade alta de peixe',
    imgUrl: ff_pt,
    imgUrl1: ff_en_1,
    imgUrl2: ff_en_2,
    imgUrl3: ff_en_3,
  },
  continue: 'Continuar'
}
