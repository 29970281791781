import Fishing from './index.jsx'
import { connect } from 'react-redux'
import {
  changingDisplay,
  setActiveSlide,
  setActiveRoute,
  setKnotsPage
} from '../../store/fish/actions'

const mapStateToProps = (state) => {
  return {
    activeRoute: state.fish.activeRoute,
    activeDisplay: state.fish.activeDisplay,
    activeStep: state.fish.activeStep,
    activeSlide: state.fish.activeSlide,
    animationIsActive: state.fish.animationIsActive,
    email: state.fish.email,
    location: state.router.location,
    knotsPage: state.fish.knotsPage
  }
}

const mapDispatchToProps = {
  changingDisplay,
  setActiveSlide,
  setActiveRoute,
  setKnotsPage
}

export default connect(mapStateToProps, mapDispatchToProps)(Fishing)
