import React from 'preact/compat'
import { Intro } from './styled'
import fish from './../../../assets/other/fish.svg'
import { userGetStarted } from '../../../amplitude/events'

export default function IntroComponent (props) {
  const { changingDisplay } = props
  return (
    <Intro>
      <Intro.Top>
        <Intro.Icon>
          <img src={fish} alt="fish"/>
        </Intro.Icon>
        <Intro.Title>
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Let's personalize your profile
        </Intro.Title>
        <Intro.Text>
          Boost your catch performance by customizing the application for yourself
        </Intro.Text>
      </Intro.Top>
      <Intro.Bottom>
        <Intro.Button onClick={() => {
          changingDisplay('questions')
          userGetStarted()
        }}>
          Get Started
        </Intro.Button>
      </Intro.Bottom>
    </Intro>
  )
}