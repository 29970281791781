import React, { useState, useEffect, useRef } from 'preact/compat'
// import ReactCssTransitionGroup from 'react-transition-group'
import { Question, AnswerDefault } from './styled'
import { questions } from '../../../constants/questions'
import { createAnswerEvent } from './../../../amplitude/events'

export default function QuestionsComponent(props) {
  const {
    activeStep,
    changingQuestion,
    changingDisplay,
    changingFinishLoading,
    isFinishSecond
  } = props
  const activeQuestion = questions[activeStep -1]

  // choice default inputs
  const [activeIndex, setActiveIndex] = useState(undefined)
  // choice checkboxes
  const [activeCheckboxIndex, setActiveCheckbox] = useState([])

  const questionWrapper = useRef()


  useEffect(() => {
    if(isFinishSecond) {
      createAnswerEvent(questions[4], activeIndex)
    }
    if(activeStep === questions.length && isFinishSecond) {
      handleLastQuestionClick()
      return false
    }
    if(activeStep === questions.length) {
      changingFinishLoading()
      return false
    }
    if(activeStep === questions.length - 1) {
      createAnswerEvent(questions[3], activeIndex)
      setTimeout(() => changingDisplay('finish'), 500)
      return false
    }
    if(activeIndex !== undefined) {
      questionWrapper.current.classList.add('animated')
      setTimeout(() => {questionWrapper.current.classList.remove('animated')}, 2000)
      setTimeout(() => changingQuestion(activeStep + 1, questions.length ), 500)
    }
  }, [activeIndex])

  useEffect(() => {
    setAnswers(activeStep, activeIndex, activeCheckboxIndex)
    setActiveIndex(undefined)
    setActiveCheckbox([])
    window.scrollTo(0, 0)
  }, [activeStep])


  const handleSetActiveIndex = (index) => {
    setActiveIndex(index)
  }

  const handleContinueOnClick = (activeStep) => {
    questionWrapper.current.classList.add('animated')
    setTimeout(() => {questionWrapper.current.classList.remove('animated')}, 2000)
    setTimeout(() => changingQuestion(activeStep + 1, questions.length ), 500)
  }

  const handleLastQuestionClick = () => {
    setTimeout(() => changingDisplay('finish'), 1000)
  }


  const handleChangeActiveCheckboxes = (activeIndex) => {
    let actives = [...activeCheckboxIndex]
    const isElement = actives.findIndex((item) => item === activeIndex)
    if(isElement === -1) {
      actives.push(activeIndex)
      setActiveCheckbox(actives)
    } else {
      actives.splice(isElement, 1)
      setActiveCheckbox(actives)
    }
  }

  // questions numbers
  const setAnswers = (activeStep, activeIndex, activeCheckboxIndex) => {
    switch (activeStep) {
      // question 1 answers
      case 2:
        createAnswerEvent(questions[0], activeIndex)
        break
      // question 2 answers
      case 3:
        createAnswerEvent(questions[1], activeCheckboxIndex)
        break
      // question 3 answers
      case 4:
        createAnswerEvent(questions[2], activeCheckboxIndex)
    }
  }

  return (
    <Question>
      <Question.StepsWrapper>
        <Question.Steps>
          {
            questions.map((step, index) => {
              if(index < questions.length -1) {
                return (
                  <Question.StepsItem key={index} className={(activeStep > index) ? 'active' : ''}/>
                )
              }
            })
          }
        </Question.Steps>
      </Question.StepsWrapper>
      <Question.Wrapper ref={questionWrapper}>
        <Question.Title>
          { activeQuestion.question }
        </Question.Title>
        {
          activeQuestion.isCheckbox && (
            <Question.Tooltip>
              Choose as many as you like
            </Question.Tooltip>
          )
        }
        <Question.Answers className={activeCheckboxIndex.length !== 0 ? 'active' : ''}>
          {
            activeQuestion.answers.map((question, index) => {
              if(activeQuestion.isCheckbox) {
                return (
                  <AnswerDefault
                    key={index}
                    onClick={() => handleChangeActiveCheckboxes(index)}
                    className={activeCheckboxIndex.findIndex((item) => item === index) !== -1 ? 'active-checkbox' : ''}
                  >
                    <AnswerDefault.Text>
                      {question.text}
                    </AnswerDefault.Text>
                    <AnswerDefault.Checkbox className={'checkbox'}>
                      <svg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 4.74744L4.28668 8L11 1" stroke="white" />
                      </svg>
                    </AnswerDefault.Checkbox>
                  </AnswerDefault>
                )
              } else {
                return (
                  <AnswerDefault
                    key={index}
                    onClick={() => handleSetActiveIndex(index)}
                    className={index === activeIndex ? 'active' : ''}
                  >
                    <AnswerDefault.Text>
                      {question.text}
                    </AnswerDefault.Text>
                    <AnswerDefault.Icon>
                      <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L8 7.15838L1.36005 13" />
                      </svg>
                    </AnswerDefault.Icon>
                  </AnswerDefault>
                )
              }
            })
          }
        </Question.Answers>
      </Question.Wrapper>
      {
        activeQuestion.isCheckbox && (
          <Question.Button className={activeCheckboxIndex.length !== 0 ? 'active' : ''}>
            <Question.Continue onClick={
              activeStep < questions.length
                ? () => handleContinueOnClick(activeStep) : false
                }>
              Continue
            </Question.Continue>
          </Question.Button>
        )
      }
    </Question>
  )
}