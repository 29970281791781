import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import {i18nReducer, loadTranslations, setLocale, syncTranslationWithStore} from 'react-redux-i18n'
import { createBrowserHistory } from 'history'
import thunk from 'redux-thunk'
import createRootReducer from './reducers'
import en from '../translations/en'
import ja from '../translations/ja'
import pt from '../translations/pt'
import es from '../translations/es'
import fr from '../translations/fr'
import de from '../translations/de'
import id from '../translations/id'

const history = createBrowserHistory()

const translationsObject = {
  en,
  ja,
  pt,
  es,
  fr,
  de,
  id
}

const locale = navigator.language.split('-')[0]

const lang = locale in translationsObject ? locale : 'en'

const initialState = {}
const enhancers = []
const middleware = [thunk, routerMiddleware(history)]

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension({ trace: true, traceLimit: 25 }))
  }
}

const composedEnhancers = compose(
  applyMiddleware(...middleware),
  ...enhancers,
)

const store = createStore(
  createRootReducer(history, i18nReducer),
  initialState,
  composedEnhancers,
)
syncTranslationWithStore(store)
store.dispatch(loadTranslations(translationsObject))
store.dispatch(setLocale(lang))

export default store
