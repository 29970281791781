export const ttFirstScreenAction = () => {
  if (window.fbq != null) { 
    window.ttq.track('ViewContent')
  }
}

export const ttGetStartedScreenAction = () => {
  if (window.fbq != null) { 
    window.ttq.track('AddToWishlist')
  }
}

export const ttCompleteScreenAction = () => {
  if (window.fbq != null) { 
    window.ttq.track('AddToCart')
  }
}

export const ttOpenAppStoreAction = () => {
  if (window.fbq != null) { 
    window.ttq.track('AddPaymentInfo')
  }
}

