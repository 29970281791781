import React, { Fragment, useEffect, useState } from 'preact/compat'
import { Wrapper } from './styled'
import {
  B_VARIANT_ROUTE,
  BASE_ROUTE, C_VARIANT_ROUTE, EXTERNAL_PRIVACY, EXTERNAL_TERMS,
  FORECAST_ROUTE,
  KNOTS_ROUTE,
  QUIZ_ROUTE,
  SLIDER_ROUTE
} from '../../constants/routes'
import Slider from './Slider'
import Intro from './Intro'
import Finish from './Finish/FinishContainer'
import Questions  from './Questions/QuestionsContainer'
import {firstOpenAction, onboardingLoading, userStepsBegin} from '../../amplitude/events'
import { fbGetStartedScreenAction } from '../../fbpixel/events'
import { ttGetStartedScreenAction } from '../../ttpixel/events'
import { clickUrl } from '../../tracking/links'
import amplitude from 'amplitude-js'
import { API_KEY } from '../../amplitude/options'
import FishingForecast from './FishingForecast/FishingForecast'
import {I18n} from 'react-redux-i18n'
import Cookies from 'js-cookie'
import {
  fetchAndActivate,
  ensureInitialized,
  getAll,
  getString,
} from 'firebase/remote-config'
import { remoteConfig } from './../../firebase'
import { experimentsAppliedAction, userPropertyPreInsertProp, configRequestCompletedAction, stepEmailComplete  } from '../../amplitude/events'
import {checkCookiesFunction, getEmailStatus} from '../../utils/helper'
import { MAX_FETCH_TIME } from '../../constants/data'

const sliderRender = (changingDisplay, activeSlide, setActiveSlide, activeRoute, route, email, loading, forecastView) => {

  // slider step changing
  const sliderStepChanging = (route) => {
    if (route === FORECAST_ROUTE) {
      return
    }
    if ((route === B_VARIANT_ROUTE || route === C_VARIANT_ROUTE) && activeSlide === 2) {
      handleGoToStore()
    }
    if(activeSlide === 4) {
      changingDisplay('intro')
      fbGetStartedScreenAction()
      ttGetStartedScreenAction()
      userStepsBegin(activeSlide)
      return
    }
    else {
      setActiveSlide(activeSlide + 1)
      userStepsBegin(activeSlide)
    }
  }

  const handleGoToStore = () => {
    userStepsBegin()
    setTimeout(() => {
      redirectFunction()
    }, 1000)
  }

  const handleGoToStoreKnots = () => {
    userStepsBegin()
    setTimeout(() => {
      window.location.href = clickUrl()
    }, 1000)
  }

  const redirectFunction = () => {
    window.location.href = clickUrl()
  }

  const handleVariantContinueClick = () => {
    const emailStatus = getEmailStatus(email)
    stepEmailComplete('continue', emailStatus)
    userStepsBegin()
    setTimeout(() => {
      window.location.href = clickUrl(email)
    }, 1000)
  }

  const privacyBlockRender = () => {
    return (
      activeSlide === 1 ? (
        <Wrapper.Privacy>
          {
            <a href={EXTERNAL_PRIVACY} rel="noreferrer" target="_blank" >
              Privacy Policy
            </a>
          }
        </Wrapper.Privacy>
      ) : (
        <Wrapper.Privacy style={{maxWidth: 165, marginLeft: 'auto', marginRight: 'auto'}}>
            <span>
              By continuing you agree to the {' '}
            </span>
            <a href={EXTERNAL_TERMS} rel="noreferrer" target="_blank" style={{color: 'black'}}>
              Terms of Use
            </a>
            <span>
             {' '} and {' '}
            </span>
            <a href={EXTERNAL_PRIVACY} rel="noreferrer" target="_blank"  style={{color: 'black'}}>
              Privacy Policy
            </a>
        </Wrapper.Privacy>
      )
    )
  }

  return (
    <Fragment>
      {route === FORECAST_ROUTE ?
        <FishingForecast loading={loading} forecastView={forecastView}/> :
        <Slider setActiveSlide={setActiveSlide} activeSlide={activeSlide} route={route} />}
      <Wrapper.ButtonBlock>
        <Wrapper.Button isForecastRoute={route === FORECAST_ROUTE} onClick={() => {
          if (route === FORECAST_ROUTE ) {
            handleGoToStore()
          }
          if(route === KNOTS_ROUTE) {
            handleGoToStoreKnots()
          }
          if (route === B_VARIANT_ROUTE || route === C_VARIANT_ROUTE) {
            activeSlide === 2
              ? handleVariantContinueClick()
              : sliderStepChanging(route)
          }
          else {
            activeSlide === 4 && activeRoute === SLIDER_ROUTE
              ? redirectFunction()
              : sliderStepChanging(route)
          }
        }}>
          {route === FORECAST_ROUTE ? I18n.t('continue') : 'Continue'}
        </Wrapper.Button>
      </Wrapper.ButtonBlock>
      {
         privacyBlockRender()
      }
    </Fragment>
  )
}

const introRender = (changingDisplay) => {
  return (
    <Intro changingDisplay={changingDisplay}/>
  )
}

const questionsRender = () => {
  return (
    <Questions />
  )
}

const finishrender = () => {
  return (
    <Finish/>
  )
}


export default function Fishing(props) {
  const {
    activeDisplay,
    changingDisplay,
    activeSlide,
    activeRoute,
    setActiveSlide,
    animationIsActive,
    setActiveRoute,
    route,
    email,
    setKnotsPage,
    knotsPage
  } = props

  const [loading, setLoading] = useState(true)
  const [forecastView, setForecastView] = useState(0)

  const remoteFunction = () => {
    // let isSuccess = true
    // let errorText
    // let timeoutProp
    // let end
    // const start = new Date().getTime()
    //
    // const timeout = setTimeout(() => {
    //   timeoutProp = true
    //   let defaultPage = 'forecast_none'
    //   experimentsAppliedAction('forecast_out')
    //   onboardingLoading(null, defaultPage)
    //   setUrlFunction(defaultPage)
    //   userPropertyPreInsertProp('experiments', 'forecast_out')
    //   checkCookiesFunction('forecast_out', 'experiments')
    //   firstOpenAction()
    //   errorText = 'Timeout'
    //   isSuccess = false
    //   setLoading(false)
    // }, MAX_FETCH_TIME)
    // fetchAndActivate(remoteConfig)
    //   .then(() => {
    //     return getAll(remoteConfig)
    //   })
    //   .then(() => {
    //     let forecastPage = getString(remoteConfig, 'forecast_page')
    //     clearTimeout(timeout)
    //     end = new Date().getTime()
    //     if (!timeoutProp) {
    //       experimentsAppliedAction(forecastPage)
    //       userPropertyPreInsertProp('experiments', forecastPage)
    //       checkCookiesFunction(forecastPage, 'experiments')
    //       setUrlFunction(forecastPage)
    //       onboardingLoading(null, forecastPage)
    //       firstOpenAction()
    //       setLoading(false)
    //     } else {
    //       return false
    //     }
    //   })
    //   .catch((e) => {
    //     isSuccess = false
    //     errorText = e
    //   })
    //   .finally(() => {
    //     end = new Date().getTime()
    //     configRequestCompletedAction(isSuccess, errorText, end - start)
    //   })
    setForecastView(1)
    setLoading(false)
  }

  const remoteFunctionKnots = () => {
    let isSuccess = true
    let errorText
    let timeoutProp
    let end
    const start = new Date().getTime()

    const timeout = setTimeout(() => {
      timeoutProp = true
      let defaultPage = 'knots_none'
      let experiments = ['knots_out']
      experimentsAppliedAction(experiments)
      onboardingLoading(null, defaultPage)
      setKnotsPage(defaultPage)
      userPropertyPreInsertProp('experiments', experiments)
      checkCookiesFunction(experiments.join(','), 'experiments')
      firstOpenAction()
      errorText = 'Timeout'
      isSuccess = false
      setLoading(false)
    }, MAX_FETCH_TIME)
    fetchAndActivate(remoteConfig)
      .then(() => ensureInitialized(remoteConfig))
      .then(() => getAll(remoteConfig))
      .then(() => {
        const page = getString(remoteConfig, 'knots_page')
        const knotsPage = page === 'none' ? 'knots_none' : page
        const experiments = JSON.parse(getString(remoteConfig, 'experiments'))
        clearTimeout(timeout)
        if (!timeoutProp) {
          experimentsAppliedAction(experiments)
          userPropertyPreInsertProp('experiments', experiments)
          checkCookiesFunction(experiments.join(','), 'experiments')
          setKnotsPage(knotsPage)
          onboardingLoading(null, knotsPage)
          firstOpenAction()
          setLoading(false)
        } else {
          return false
        }
      })
      .catch((e) => {
        isSuccess = false
        errorText = e
      })
      .finally(() => {
        end = new Date().getTime()
        configRequestCompletedAction(isSuccess, errorText, end - start)
      })
  }


  // for Ios height calculating
  useEffect(() => {
    let userId = Cookies.get('avilunaid')
    amplitude.getInstance().init(
      API_KEY,
      userId,
      {
        includeReferrer: true,
        includeUtm: true,
        includeGclid: true,
        includeFbclid: true,
        logAttributionCapturedEvent: true
      }
    )

    if (route === FORECAST_ROUTE) {
      remoteFunction()
    } else if (route === KNOTS_ROUTE) {
      remoteFunctionKnots()
    }
    else if (route !== B_VARIANT_ROUTE || route !== C_VARIANT_ROUTE) {
      onboardingLoading(route)
    }
    const activeDisplay = compareFunction(route)
    setActiveRoute(route)
    changingDisplay(activeDisplay)
    // heightCalculating(activeDisplay)
  }, [])

  const compareFunction = (route) => {
    let activeDisplay
    switch (route) {
      case BASE_ROUTE:
        activeDisplay = 'slider'
        break
      case QUIZ_ROUTE:
        activeDisplay = 'intro'
        break
      case SLIDER_ROUTE:
        activeDisplay = 'slider'
        break
      case FORECAST_ROUTE:
        activeDisplay = 'slider'
        break
      case B_VARIANT_ROUTE:
        activeDisplay = 'slider'
        break
      case C_VARIANT_ROUTE:
        activeDisplay = 'slider'
        break
      case KNOTS_ROUTE:
        activeDisplay = 'slider'
    }
    return activeDisplay
  }


  return (
    <Wrapper.Bg activeDisplay={activeDisplay}>
      <Wrapper activeDisplay={activeDisplay} className={animationIsActive ? 'animated' : '' } style={{height: 'auto'}}>
        {
          activeDisplay === 'slider' && sliderRender(changingDisplay, activeSlide, setActiveSlide, activeRoute, route, email, loading, forecastView, knotsPage)
        }
        {
          activeDisplay === 'intro' && introRender(changingDisplay, activeRoute)
        }
        {
          activeDisplay === 'questions' && questionsRender()
        }
        {
          activeDisplay === 'finish' && finishrender()
        }
      </Wrapper>
    </Wrapper.Bg>
  )
}
