import styled, { keyframes } from 'styled-components'

const Finish  = styled.div`
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  align-items: center;
  flex-grow: 1;
  justify-content: space-between;
  @media(max-height: 669px) {
    padding-top: 1rem;
  }
`

Finish.Title = styled.div`
  height: 8.6875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* margin-bottom: 2.9375rem; */
  font-weight: 800;
  font-size: 1.75rem;
  line-height: 2.5rem;
  color: #00394A;
  @media(max-height: 669px) {
    height: 5rem;
    /* margin-bottom: 0; */
  }
`

Finish.Icon = styled.div`
  width: 10rem;
  height: 6.4375rem;
  /* margin-bottom: 0.8125rem; */
  @media(max-height: 669px) {
    /* margin-bottom: 0; */
  }
`

Finish.Wave = styled.div`
  position: relative;
  z-index: 1;
`

Finish.Wave.Big = styled.div`
  position: absolute;
  left: 0;
  top: 3rem;
`

Finish.Wave.Small = styled.div`
  position: absolute;
  left: 1.6875rem;
  top: 3.1rem;
`

Finish.Float = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
  transition-delay: 0.3s;
  &.visible {
    opacity: 1;
  }
`

Finish.Float.Background = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 6.1875rem;
  img {

  }
`

Finish.Float.Bg = styled.div`
  display: block;
  position: absolute;
  height: 99%;
  width: 3.125rem;
  bottom: 0;
  left: 3.4375rem;
  background: #A8DBE6;
  overflow: hidden;
`

Finish.Float.Color = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: 0.1s;
  transform: rotate(180deg);
`

Finish.Float.Blue = styled.div`
  height: 1.7rem;
  width: 100%;
  background: #00829E;
  flex-shrink: 0;
`

Finish.Float.Red = styled.div`
  height: 2.6rem;
  width: 100%;
  background: #D73731;
  flex-shrink: 0;
`

Finish.Float.Blue.Bottom = styled.div`
  height: 1.95rem;
  width: 100%;
  background: #00829E;
  flex-shrink: 0;
`

Finish.Precent = styled.div`
  font-family: 'Avenir', sans-serif;
  font-weight: 900;
  font-size: 1.25rem;
  text-align: center;
  line-height: 3.375rem;
  color: #00394A;
  margin-bottom: 2.9375rem;
  .number {
    font-size: 2.5rem;
  }
  @media(max-height: 669px) {
    margin-bottom: 0;
    height: 4rem;
  }
`

Finish.Items = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`

const spinFast = keyframes`
  0% {
      transform: rotate(0deg);
      background: #ffffff;
      border-color: #DCE8EC;
      border-top-color: #2BD684;
    }
    80% {
      transform: rotate(360deg);
      background: #ffffff;
      border-color: #DCE8EC;
      border-top-color: #2BD684;
    }
    100% {
      transform: rotate(360deg);
      background: #2BD684;
      border-color: #2BD684;
      border-top-color: #2BD684;
    }
`

Finish.Item = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 0.75rem;
  opacity: 0;
  transition: 0.2s;
  &.visible {
    opacity: 1;
    .loading {
      animation: 2s ${spinFast} linear;
      animation-fill-mode: forwards;
      opacity: 1;
      &-ready {
        opacity: 1;
        animation-fill-mode: forwards;
        animation: 0s ${spinFast} linear;
        border-color: #2BD684;
        border-top-color: #2BD684;
        background: #2BD684;
      }
    }
  }
`

Finish.Loading = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
  border-radius: 50%;
  border: 3px solid #DCE8EC;
  box-sizing: border-box;
  border-top-color: #2BD684;
  opacity: 0;
  margin-right: 0.625rem;
  animation-fill-mode: forwards;
  display: flex;
  align-items: center;
  justify-content: center;
`

Finish.Text = styled.div`
  font-size: 1.125rem;
  font-weight: 500;
  color: #00394A;
  display: flex;
  justify-content: flex-start;
  text-align: left;
`

Finish.Button = styled.div`
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  width: 100%;
  margin-top: 1rem;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.3s;
  a {
    text-decoration: none;
  }
  &.visible {
    z-index: 0;
    opacity: 1;
  }
  @media(max-height: 669px) {
    margin-top: 0;
  }
`

Finish.Button.Block = styled.div`
  width: 100%;
  height: 4rem;
  background: #00A4CA;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  font-size: 1.5rem;
  line-height: 2.1rem;
  color: #FFFFFF;
  -moz-user-select: none; /* Mozilla Firefox */
  -ms-user-select: none; /* Internet Explorer (не поддерживается) */
  -o-user-select: none; /* Opera Presto (не поддерживается) */
  -webkit-user-select: none;
`

const Animated = styled.span``

const dotAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

Animated.Dot = styled.span`
  &.first {
    animation: 0.8s ${dotAnimation} linear;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-delay: 0.5s;
  }
  &.second {
    animation-delay: 0.3s;
    animation: 0.8s ${dotAnimation} linear;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    
  }
  &.third {
    animation: 0.8s ${dotAnimation} linear;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-delay: 0.1s;
  }
`


export { Finish, Animated }